
  import { defineComponent, onMounted, reactive, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import api from "@/api/axios";
  import { Queue } from "@/hooks/usePageTab";
  import { contains } from "jquery";
  import $ from "jquery";
  import qs from "qs";
  import Toast from "@/components/UI/Toast";
  import router from "@/router";


  export default defineComponent({
    name: "Search",
    setup() {
      const keywords = ref(null);
      const sulShow = ref(false)
      const mainShow = ref(true)
      const myData=ref();
      const routerKey = useRouter();
      const showSearchType = ref(false);
      api.get("/M/Home/Search").then((res) => {
        if (res.data.success) {
          keywords.value = res.data.obj.list;
        }
      });

      type searchType =
              | "产品"
              | "新闻"
              | "课堂"
              | "帖子"
      const searchTypeList: searchType[] = [
        "产品",
        "新闻",
        "课堂",
        "帖子",

      ];
      function showType() {
        showSearchType.value = true;
        console.log(showSearchType.value);
      }
      const currentType = ref<searchType>("产品");
      const route = useRoute();
      const type = route.query.type;
      switch (type) {
        case "news":
          currentType.value = "新闻";
          break;
        case "course":
          currentType.value = "课堂";
          break;
        case "topic":
          currentType.value = "帖子";
          break;
        default:
          currentType.value = "产品";
      }
      function selectType(type: searchType) {
        showSearchType.value = false;
        currentType.value = type;
      }
      //  点击搜索
      const keyword = ref("");


      function getKeys() {
        // console.log('currentType' + currentType.value);
        if(currentType.value != '产品'){
          sulShow.value = false;
          return false;
        }
        sulShow.value = true;
        // console.log('输入框的值为' + this.keyword);
        const keyword = this.keyword;
        if (keyword == "") {
          return;
        } else {
          api.get("/M/searchKeyword/searchTenKeyWords?keyword="+keyword, myData as object).then((res) => {
            if (res.data.success) {
              const arr = res.data.data;
              if(arr){
                myData.value = arr.split('|');
                sulShow.value = true;
              }
            }
          });
        }
      }

      function filter(html: any) {
        return html
                .replace(/<(?:.|\n)*?>/gm, '')
                .replace(/(&rdquo;)/g, '"')
                .replace(/&ldquo;/g, '"')
                .replace(/&mdash;/g, '-')
                .replace(/&nbsp;/g, '')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/<[\w\s"':=/]*/, '')
      }


      function searchKey(keyword: string) {
        keyword=filter(keyword);
        if (keyword == "") {
          return Toast({
            type: 'warning',
            title: "搜索内容不能为空"
          });
        }else{
          routerKey.push({
            name: "OpenSearch",
            query: {
              keyword: keyword,
              type: currentType.value,
            },
          });
        }
      }

      function handleKeyup(e: any) {
        if(e.keyCode == 13) {
          const keyword = this.keyword;
          searchKey(keyword as string)
        }
      }

      function focusFn() {
        if(currentType.value == '产品'){
          sulShow.value = true;
          mainShow.value = false;
        }
      }

      // 输入框失去焦点时
      function blurFn() {
        sulShow.value = false;
        mainShow.value = true;
      }

      //  历史记录
      const router = useRouter();
      const queue = reactive(new Queue());
      const MAX_SIZE = 8;

      onMounted(() => {
        const historySearch = localStorage.getItem("historySearch");
        if (historySearch) {
          const list = JSON.parse(historySearch);
          list.data.forEach((item: string) => {
            queue.enQueue(item);

            let arr: any[] = []
            queue.value().forEach((item, index) => {
              if (!item) {
                queue.value().splice(index, 1)
              }
            })
            arr = Array.from(new Set(queue.value()))
            queue.value().splice(0, queue.value().length)
            queue.value().push(...arr)
          });
        }




      });

      function clear() {
        queue.clear();
        localStorage.removeItem("historySearch");
      }

      function search(keyword: string) {
        if (queue.size() >= MAX_SIZE) {
          queue.outRearQueue();
        }
        queue.enFrontQueue(keyword);

        localStorage.setItem("historySearch", JSON.stringify(queue));

        router.push({
          name: "OpenSearch",
          query: {
            keyword: keyword,
            type: currentType.value,
          },
        });
      }




      function onBack() {
        router.back()
      }
      return {
        keywords,
        myData,
        sulShow,
        mainShow,
        showSearchType,
        searchTypeList,
        selectType,
        currentType,
        queue,
        keyword,
        search,
        showType,
        focusFn,
        blurFn,
        getKeys,
        searchKey,
        handleKeyup,
        clear,
        onBack
      };
    },
  });




<template>
  <div class="header" style="position: relative">
    <div class="search-wapper">
      <div class="search-list">
        <div @click="showType()" style="width: 100%; height: 100%">
          <span>{{ currentType }}</span>
          <img src="@/assets/img/arrow-down.png" alt="" />
        </div>
        <transition tag="div" name="list">
          <ul v-if="showSearchType">
            <li
                    v-for="(item, index) in searchTypeList"
                    :key="index"
                    @click="selectType(item)"
            >
              {{ item }}
            </li>
          </ul>
        </transition>
      </div>
      <div class="form-input-div">
        <input
          id="search"
          v-model="keyword"
          placeholder="请输入关键词..."
          type="search"
          ref="refInput"
          class="s-input"
          @input="getKeys"
          @keyup.enter="handleKeyup"
          @focus="focusFn"
          @blur="blurFn"
        />
      </div>
    </div>
    <div @click="searchKey(keyword)" class="cancel search">
      <img
              class="search-icon"
              src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/search20201110.png"
              alt=""
      />
    </div>

  </div>
  <ul class="s-ul" v-if="sulShow" @click="blurFn">
    <li
            @mousedown.stop="searchKey(item)"
            v-for="(item, index) in myData"
            :key="index"
    >
      <a href="javascript:;" v-html="item">
      </a>
    </li>
  </ul>
  <!--  -->
  <div>
  </div>
  <!--  -->
  <div class="main" v-show="mainShow">
    <div class="history-search-wapper">
      <div class="title">
        <img
                class="icon"
                src="@/assets/img/search/icon61.png"
                alt=""
        />
        历史搜索

        <div class="clear-btn" @click="clear()">
          <img
                  class="clear-icon"
                  src="@/assets/img/search/icon62.png"
                  alt=""
          />
          清空
        </div>
      </div>

      <ul class="search-keyword-ul history-ul">
        <li
                @click="search(item)"
                v-for="(item, index) in queue.value()"
                :key="index"
        >
          <a href="javascript:;">
            {{ item }}
          </a>
        </li>
      </ul>
    </div>
    <div class="hot-search-wapper">
      <div class="title">
        <img
                class="icon"
                src="@/assets/img/search/icon63.png"
                alt=""
        />

        热门搜索
      </div>

      <ul class="search-keyword-ul">
        <li
                v-for="(item, index) in keywords"
                :key="index"
                @click="search(item.searchKeyword)"
        >
          <a href="javascript:;">
            {{ item.searchKeyword }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
                href="https://tb.53kf.com/code/client/a7c96e4ceb10e87d6fc792aec24104e05/3"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
  import { defineComponent, onMounted, reactive, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import api from "@/api/axios";
  import { Queue } from "@/hooks/usePageTab";
  import { contains } from "jquery";
  import $ from "jquery";
  import qs from "qs";
  import Toast from "@/components/UI/Toast";
  import router from "@/router";


  export default defineComponent({
    name: "Search",
    setup() {
      const keywords = ref(null);
      const sulShow = ref(false)
      const mainShow = ref(true)
      const myData=ref();
      const routerKey = useRouter();
      const showSearchType = ref(false);
      api.get("/M/Home/Search").then((res) => {
        if (res.data.success) {
          keywords.value = res.data.obj.list;
        }
      });

      type searchType =
              | "产品"
              | "新闻"
              | "课堂"
              | "帖子"
      const searchTypeList: searchType[] = [
        "产品",
        "新闻",
        "课堂",
        "帖子",

      ];
      function showType() {
        showSearchType.value = true;
        console.log(showSearchType.value);
      }
      const currentType = ref<searchType>("产品");
      const route = useRoute();
      const type = route.query.type;
      switch (type) {
        case "news":
          currentType.value = "新闻";
          break;
        case "course":
          currentType.value = "课堂";
          break;
        case "topic":
          currentType.value = "帖子";
          break;
        default:
          currentType.value = "产品";
      }
      function selectType(type: searchType) {
        showSearchType.value = false;
        currentType.value = type;
      }
      //  点击搜索
      const keyword = ref("");


      function getKeys() {
        // console.log('currentType' + currentType.value);
        if(currentType.value != '产品'){
          sulShow.value = false;
          return false;
        }
        sulShow.value = true;
        // console.log('输入框的值为' + this.keyword);
        const keyword = this.keyword;
        if (keyword == "") {
          return;
        } else {
          api.get("/M/searchKeyword/searchTenKeyWords?keyword="+keyword, myData as object).then((res) => {
            if (res.data.success) {
              const arr = res.data.data;
              if(arr){
                myData.value = arr.split('|');
                sulShow.value = true;
              }
            }
          });
        }
      }

      function filter(html: any) {
        return html
                .replace(/<(?:.|\n)*?>/gm, '')
                .replace(/(&rdquo;)/g, '"')
                .replace(/&ldquo;/g, '"')
                .replace(/&mdash;/g, '-')
                .replace(/&nbsp;/g, '')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/<[\w\s"':=/]*/, '')
      }


      function searchKey(keyword: string) {
        keyword=filter(keyword);
        if (keyword == "") {
          return Toast({
            type: 'warning',
            title: "搜索内容不能为空"
          });
        }else{
          routerKey.push({
            name: "OpenSearch",
            query: {
              keyword: keyword,
              type: currentType.value,
            },
          });
        }
      }

      function handleKeyup(e: any) {
        if(e.keyCode == 13) {
          const keyword = this.keyword;
          searchKey(keyword as string)
        }
      }

      function focusFn() {
        if(currentType.value == '产品'){
          sulShow.value = true;
          mainShow.value = false;
        }
      }

      // 输入框失去焦点时
      function blurFn() {
        sulShow.value = false;
        mainShow.value = true;
      }

      //  历史记录
      const router = useRouter();
      const queue = reactive(new Queue());
      const MAX_SIZE = 8;

      onMounted(() => {
        const historySearch = localStorage.getItem("historySearch");
        if (historySearch) {
          const list = JSON.parse(historySearch);
          list.data.forEach((item: string) => {
            queue.enQueue(item);

            let arr: any[] = []
            queue.value().forEach((item, index) => {
              if (!item) {
                queue.value().splice(index, 1)
              }
            })
            arr = Array.from(new Set(queue.value()))
            queue.value().splice(0, queue.value().length)
            queue.value().push(...arr)
          });
        }




      });

      function clear() {
        queue.clear();
        localStorage.removeItem("historySearch");
      }

      function search(keyword: string) {
        if (queue.size() >= MAX_SIZE) {
          queue.outRearQueue();
        }
        queue.enFrontQueue(keyword);

        localStorage.setItem("historySearch", JSON.stringify(queue));

        router.push({
          name: "OpenSearch",
          query: {
            keyword: keyword,
            type: currentType.value,
          },
        });
      }




      function onBack() {
        router.back()
      }
      return {
        keywords,
        myData,
        sulShow,
        mainShow,
        showSearchType,
        searchTypeList,
        selectType,
        currentType,
        queue,
        keyword,
        search,
        showType,
        focusFn,
        blurFn,
        getKeys,
        searchKey,
        handleKeyup,
        clear,
        onBack
      };
    },
  });



</script>

<style lang="scss" scoped>
  a {
    color: #000;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  li {
    list-style: none;
  }
  .header {
    width: 355px;
    padding: 0 20px;
    height: 36.5px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 55px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  #search{
    border: none;
    outline: none;
  }

  .search-wapper {
    width: 100%;
    // background-color: #f2f2f2;
    position: relative;
    /* // padding-left: 40px; */
    box-sizing: border-box;
    height: 36.5px;
  }
  .search-icon {
    /* position: absolute; */
    /* left: 10px;
      top: 50%;
      transform: translateY(-50%); */
    width: 22.5px;
    height: 22.5px;
    /* vertical-align: middle; */
  }

  .search-wapper .form-input-div {
    display: inline-block;
    position: relative;
    padding-left: 0.26667rem;
    box-sizing: border-box;
    width: 200px;
    height: 0.97333rem;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    z-index: 1;
  }

  .search-wapper .form-input-div input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    vertical-align: top;
    line-height: 36.5px;
    height: 36.5px;
    box-sizing: border-box;
  }

  .cancel {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 15px;
    line-height: 36.5px;
    padding: 0 20px;
    background-color: #df0024;
    border-left: 1px solid #fff;
    display: flex;
    align-items: center;
  }

  .history-search-wapper,
  .hot-search-wapper {
    width: 355px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .title {
    overflow: hidden;
    color: #666666;
    font-size: 15px;
  }

  .icon {
    width: 13px;
    height: 13px;
    vertical-align: middle;
    position: relative;
    top: -3px;
  }

  .clear-icon {
    width: 13px;
    height: 13px;
    vertical-align: middle;
    position: relative;
    top: -3px;
  }

  .clear-btn {
    float: right;
  }

  .search-keyword-ul {
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .search-keyword-ul li {
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    width: 80px;
    height: 30px;
    background-color: #f4f4f4;
    border-radius: 2.5px;
    line-height: 31px;
    text-align: center;
    color: #666666;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .search-keyword-ul li a {
    color: #666666;
    font-size: 14px;
  }

  .search-keyword-ul li:nth-child(4n) {
    margin-right: 0;
  }

  .hot-search-wapper {
    margin-top: 20px;
  }

  .search-list {
    width: 100px;
    float: left;
    height: 36.5px;
    position: relative;
    border-right: 1px solid #fff;
    box-sizing: border-box;
  }

  .search-list img {
    position: absolute;
    right: 2px;
    width: 25px;
    top: 6px;
  }

  .search-list span {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    box-sizing: border-box;
    border: 1px solid #f2f2f2;
    outline: none;
    float: left;
    padding-left: 25.6px;
    font-size: 14px;
    text-align: left;
    line-height: 36.5px;
  }

  .search-list ul {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 14px;
    top: 37px;
    z-index: 2;
    height: 150px;
    overflow: hidden;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    box-sizing: border-box;
  }

  .search-list ul li {
    width: 100%;
    height: 30px;
    background: #f2f2f2;
    color: #666;
    /* // line-height: 30px; */
    line-height: 32px;
    border: 1px solid #f2f2f2;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid #f4f4f4;
    font-size: 14px;
    color: #666;
    background-color: #fff;
    z-index: 100;
  }
  footer a {
    font-size: 14px;
    color: #666;
    display: block;
  }

  .footer-li {
    float: left;
    width: 17%;
    text-align: center;
    position: relative;
  }
  .footer-li img {
    display: block;
    width: 19.5px;
    height: 19.5px;
    margin: 5px auto;
  }

  .footer-li .active-status {
    position: absolute;
    display: none;
    left: 50%;
    margin-left: -9.75px;
    top: 0;
    z-index: 10;
    background-color: #fff;
  }

  .footer-nav .active a {
    color: #df0024;
  }

  .footer-nav .active .active-status {
    display: block;
  }

  footer .button {
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #e7c527;
    color: #fff;
    border-radius: 36px;
    margin-top: 8px;
    margin-right: 10px;
  }
  footer .button a {
    color: #fff;
  }

  .download {
    float: right;
  }

  .s-input:focus {
    border-radius: 5px 0px 0px 0px;
    outline: 0;
    border: 2px solid #00a0e9;
    border-right-style: none;
    border-bottom-color: rgb(238, 235, 235);
  }

  .s-ul {
    overflow: hidden;
    width: 6.4rem;
    margin-left: 2.2rem;
    li {
      padding-left: 10px;
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left:0.5rem;
    }
  }
  .ifacitve {
    color: #00a0e9;
  }

</style>


